import React from 'react';

// import i18n from '../../i18n';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ucFirst } from '../../utils/caseconverters';
import styles from './Picture.module.scss';

const WithLazyLoad = ({ lazy, children }) =>
    lazy ? <LazyLoad once>{children}</LazyLoad> : children;

const Picture = ({
    size = null,
    image = null,
    classNameBorderRadius = '',
    lazy = true,
}) => {
    return (
        <>
            {size && image && image.renditions && (
                <div
                    className={classNames(
                        styles['Picture'],
                        styles['Picture--' + ucFirst(size)]
                    )}>
                    {image && (
                        <WithLazyLoad lazy={lazy}>
                            <div
                                className={classNames(
                                    classNameBorderRadius,
                                    styles['Picture__Picture'],
                                    styles['Picture__Picture--' + ucFirst(size)]
                                )}
                                style={{
                                    backgroundImage: `url(${image.renditions[size].src})`,
                                    backgroundPosition: `${image.focal.x} ${image.focal.y}`,
                                }}></div>
                        </WithLazyLoad>
                    )}
                </div>
            )}
        </>
    );
};

Picture.propTypes = {
    image: PropTypes.shape({
        renditions: PropTypes.object.isRequired,
    }),
    shadow: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    classNameBorderRadius: PropTypes.string,
    lazy: PropTypes.bool,
};

export default Picture;
